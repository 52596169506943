const testimonials = [
  {
    id: 1,
    name: "Olesia Senchenko",
    title: "Waxing master",
    org: "Sugar Madam",
    des: "I am really satisfied with what Roman has developed. My new website is awesome and it has already attracted a banch of new clients. Strongly recommend Roman as a great web developer!  ",
  },
  {
    id: 1,
    name: "Iryna Zinchenko",
    title: "Professional video-maker",
    org: "IRI Zinchenko",
    des: "Strongly recommend Roman as a great web developer! I am really satisfied with what Roman has developed. My new website is awesome and it has already attracted a banch of new clients.",
  },
  {
    id: 1,
    name: "Andrii Isopenko",
    title: "Enterpreneur, shop owner",
    org: "Kontakt",
    des: "My new website is awesome and it has already attracted a banch of new clients. I am really satisfied with what Roman has developed. Strongly recommend Roman as a great web developer!  ",
  },
];

export default testimonials;
